.dashboard{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.counter{
    width: 80%;
}
.btns{
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}
.btns a{
    padding: .25rem 1rem;
    background-color: var(--green);
    border-radius: 10px;
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);    
    color: var(--white);
    border: 2px solid var(--green);
    cursor: pointer;
    text-decoration: none;
    width: 150px;
    text-align: center;
}

.counter{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 5rem;
    margin-bottom: 0;
}
.counter aside{
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.counter aside h2{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s5);    
    color: var(--black);
    border-bottom: 3.5px solid var(--green);
    white-space: nowrap;
}
.counter aside h1{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s7);    
    color: var(--black);
    white-space: nowrap;
}