.cmsBtn{
    width: 150px;
    background-color: var(--green);
    color: var(--white);

    font-weight: 600;
    padding: .5rem 1rem !important;
    outline: none;
    border: var(--green);
    border-radius: 10px;
    margin-left: auto;
    font-family: var(--f1);
    font-weight: var(--w2);
    font-size: var(--s2);  
    cursor: pointer;
}
.cmsBtn:active{
    background-color: var(--activeGreen);
    color: var(--white);
}

.cmsForm{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.cmsForm span h2{
    font-family: var(--f1);
    font-weight: var(--w2);
    font-size: var(--s4);    
    color: var(--activeGreen);    
    padding: 1rem 5rem;
}
.cmsForm form{
    height: 80%;
    padding: 1rem 5rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
    /* flex-wrap: wrap; */
    overflow-y: scroll;
}
.cmsInputContainer{
    width: 100%;

    display: flex;
    align-items: center;
}
.cmsInputContainer label, .cmsInputRow label{
    display: inline-block;
    width: 150px;
}
.cmsInputContainer input, .cmsInputContainer textarea, .cmsInputRow input{
    width: 250px;
    padding: .25rem .5rem;
    font-family: var(--f1);
    font-weight: var(--w2);
    font-size: var(--s2);    
    color: var(--activeGreen);    

    border-radius: 5px;
    border: 1px solid var(--activeGreen);
}
.cmsInputRow{
    width: 100%;

}
.cmsInputRow input:nth-child(2){
    margin-right: 2rem;
}
.cmsInputRow button{
    margin-left: 2rem;
}
.cmsSubmit{
    width: 100%;
    display: flex;
}

/* Image ========================================= */
.FormimageContainer{
    width: 250px;
    aspect-ratio: 1/1;
    border: 1px solid var(--darkGray);
    border-radius: 16px;
    position: relative;
    margin-top: 1rem;
    /* margin-left: 150px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.FormimageContainer input[type='file']{
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.uploadImage{
    width: 100%;
    height: 100%;
    padding: .5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}
.uploadImage img{
    width: 30%;
    object-fit: contain;
    object-position: center;
}