.galleryMain{
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.galleryMain header{
    display: flex;
    justify-content: center;
    align-items: center;
}
.galleryMain header div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin-bottom: 5rem; */
}
.galleryMain header h2{
    /* width: 100%; */
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s7);    
    color: var(--black);
    text-align: center;
    padding-bottom: .5rem;
}
.gallery{
    width: 90%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    position: relative;
}
.galleryContainer{
    width: clamp(140px, 100%, 250px);
    margin: 2rem;
    margin-top: 0;
    cursor: pointer;
}
.galleryContainer figure{
    width: 100%;
}
.galleryContainer figure img{
    width: 100%;
    object-fit: contain;
    display: block;
    border-radius: 20px;
}
.galleryContainer p{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--black);
    max-height: calc(calc(var(--s3)) * 2);
    overflow: hidden;
    line-height: var(--s3);
    position: relative;
}
/* .galleryContainer p::after{
    content: "...";
    position: absolute;
    right: 0;
    bottom: calc(-1 * var(s3) / 2);
    font-family: var(--f1);
    font-weight: var(--w4);
    font-size: var(--s4);    
    color: var(--black);
} */


.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 30px;
  }


.galleryPop{
    width: 70vw;
    height: 70vh;
    position: fixed;
    top: 0;
    left: 0;
    margin:15vh 15vw;
    background-color: var(--white);
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}
.popBG{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: -15vh;
    left: -15vw;
    z-index: 11;
    background: rgba( 6, 0, 0, 0.35 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 7px );
    -webkit-backdrop-filter: blur( 7px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.galleryPop figure{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 12;
    background-color: var(--white);
}
.galleryPop figure img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.galleryPop main{
    width: 50%;
    height: 100%;
    padding: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 12;
    background-color: var(--white);
}
.galleryPop main h3{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s4);    
    color: var(--black);
    margin-bottom: 2rem;   
}
.galleryPop main p{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);   
}
.galleryPop main aside{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}
.galleryPop main aside h4{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--text);   
}
.galleryPop main aside a{
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    font-family: var(--f3);
    font-weight: var(--w2);
    font-size: var(--s3);    
    color: var(--black);   
}
.closePop{
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-family: var(--f3);
    font-weight: var(--w2);
    font-size: var(--s4);
    cursor: pointer; 
    z-index: 13;
}
@media screen and (max-width:768px) {
    .galleryMain{
        margin-left: 0%;
        justify-content: center;
        align-items: center;
    }    
    .galleryMain header{
        margin-bottom: 0;
        margin-left: auto;
        width: 100vw;
    }
    
    .galleryPop{
        flex-direction: column;
        margin:5vh 5vw;
        width: 90vw;
        height: 90vh;
        align-items: center;
    }
    .popBG{
        top: -5vh;
        left: -5vw;
    }
    .galleryPop figure{
        width: 80%;
    }
    .galleryPop main{
        width: 80%;
        padding: 1rem;
    }
    .galleryPop main h3{
        margin-bottom: 1rem;
    }
    .galleryPop main p{
        overflow: hidden;
    }
}