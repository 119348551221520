.btnContainer{
    display: flex;
    gap: 1rem;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}
.btnText{
    padding: .5rem 1rem;
    background-color: var(--green);
    color: var(--cream) !important;
    z-index: 1;
    border-radius: 50px;
    font-family: var(--f3) !important;
    font-weight: var(--w2) !important;
    font-size: var(--s2) !important;    
}
.btnImage{
    width: 1.75rem;    
    background-image: url('images/btnArrowGre.svg');
    background-size: contain; /* Adjust as needed */
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
    margin-right: 1rem;
}
.btnContainer span{
    position: absolute;
    width: 50%;
    height: 100%;
    border-radius: 50px;
    transition:width .2s ease;
    background-color: var(--white);
}
.btnContainer:hover .btnImage{
    background-image: url('images/btnArrowWht.svg');
    animation: arrowRotate .5s ease;
}
@keyframes arrowRotate {
    from{
        transform: rotate(360deg);
    }
    to{
        transform: rotate(0deg);
    }
}
.btnContainer:hover span{
    width: 100%;
    position: absolute;
    background-color: var(--green);
    z-index: 0;      
}

.btnContainer:active span, .btnContainer:active .btnText{
    background-color: var(--activeGreen);
}

/* ========================================================================Marquee Comps */
.marquee {
    overflow: hidden;
    white-space: nowrap;
    animation: marqueeScroll linear infinite forwards;
    /* animation-duration: var(--animation, 5s)ms; */
}

@keyframes marqueeScroll {
0% {
    transform: translateX(-.5%);
    }
100% {
    transform: translateX(-10%);
    }
}

/* =========================================================== Underline */
.underline{
    /* width: 35%; */
    display: inline-block;
    height: 5px;
    background-color: var(--green);
    margin-left: auto;
    border-radius: 5px;
}

/* ========================================================= Button */
.subContainer{    
    border: none;
    background: none;
    align-items: center;
}
.subImage{    
    height: var(--s1); 
}

/* ============================ Call Btn ====================== */
.actionBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    text-decoration: none;
    cursor: pointer;
    height: 40px;
    border-radius: 25px;
    background-color: var(--green);
    transition: all .5s;
}
.toCall{
    padding: .25rem 1rem .25rem .25rem;
}
.toCall img{
    width: 1.5rem;
    margin-left: .3rem;
}
.actionBtn button{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w3);
    color: var(--white);
    text-decoration: none;
    background: transparent;
    border: none;
}
.actionBtn:hover>button{
    color: var(--white);
}

/* =======================================================Touch Submit */
.alertContainer{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;    
    margin-left: -10%;
    z-index: 12;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alertBG{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba( 6, 0, 0, 0.65 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 9px );
    -webkit-backdrop-filter: blur( 9px );
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    z-index: 11;
}
.alert{
    width: 300px;
    background-color: var(--pista);
    z-index: 12;
    padding: 1.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;

    /* box-shadow: 0px 0px 50px 5px var(--green); */
}
.alert p{
    font-size: var(--s3);
    font-family: var(--f1);
    font-weight: var(--w1);
    text-align: center;
    margin-bottom: 1rem;
}
.alert button{
    font-size: var(--s3);
    font-family: var(--f1);
    font-weight: var(--w2);
    background-color: var(--green);
    color: var(--cream);
    border: 1px solid var(--cream);
    padding: .25rem .5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all .5s;
}
.alert button:hover{
    border:1.5px solid var(--activeGreen);
}

/* Newslettre ============================================================ */
.newsLetter{
    /* width: 250px;
    height: 350px; */
    width: 70%;
    padding: 1rem;
    background-color: var(--white);
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 25px;
    /* box-shadow: 0px 4px 4px 0px #0000001A; */
    gap: 1rem;
}
.newsLetter h3{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);    
    color: var(--black);
    line-height: var(--s4);
    margin-bottom: auto;
}
.newsLetter p{
    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--grey);
}
.newsImage{
    width: 100%;
    aspect-ratio: 215/121;
    object-fit: cover;
    object-position: top;
    border-radius: 15px;
}
.newsLetter a{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: .5rem;
    text-decoration: none;
    padding-right: .5rem;
}
.newsLetter a h2{
    font-family: var(--f3);
    font-weight: var(--w2);
    font-size: var(--s2);    
    color: var(--grey);
}
.newsLetter a img{
    width: 30px;
    object-fit: contain;
}
@media screen and (max-width:768px) {
    .newsLetter{
        width: 90%;
    }
}