@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jua&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arapey:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

:root{
  --green:#32B14B;
  --activeGreen:#1E6A2D;
  --pista:#C2E8C9;
  --cream:#EBF7ED;
  --blue:#EDF6FF;  
  --balck:#0F040D;
  --white:#ffffff;
  --grey:#6F686E;
  --jikina:#E0E0E0;
  --text:#B7B4B6;

  --f1:"Raleway", sans-serif;
  --f2:"Inter", sans-serif;
  --f3:"Roboto", sans-serif;
  --f4:"Poppins", sans-serif;
  --f5:"Jua", sans-serif;
  --f6:"Arapey", serif;
  --f7:"Parisienne", cursive;

  --w1:400;
  --w2:600;
  --w3:500;

  /*
  --s1:.75rem;   12px 
  --s2:.875rem;  14px 
  --s3:1rem;     16px 
  --s4:1.375rem; 22px 
  --s5:2rem;     32px 
  --s6:2.75rem;  44px 
  --s7:3.5rem;   57px 
  */

  --s1:.75rem;  
  --s2:.875rem; 
  --s3:1rem;    
  /* --s4:1.375rem; */
  /* --s5:2rem;     */
  /* --s6:2.75rem;  */
  --s7:3.5rem;  

  /* --s1:.75rem;   */
  /* --s2:.875rem;  */
  /* --s3:1rem;     */
  --s4:clamp(1rem,1.3vw,1.375rem);
  --s5:clamp(1.65rem, 1.75vw, 2rem);    
  --s6:clamp(2.15rem ,2vw,2.75rem); 
  /* --s7:3.5rem;   */

}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
em{
  font-style: normal;
}
#root{
  width: 100%;
  height: auto;
  overflow-x: hidden;
}
.wrapper{
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.show{
  display: flex !important;
}
.hide{
  display: none !important;
}

/* CMS ============================================ */
.cmsWrapper{
  width: 90%;
  height: 90vh;
  margin: 5vh 5%;
  background-color: var(--cream);
  border: 3px solid var(--activeGreen);
  border-radius: 25px;
}
.cmsWrapper header{
  width: 100%;
  padding: 1rem 5rem;
  display: flex;
  justify-content: space-between;
}
.cmsWrapper header h2{
  font-family: var(--f6);
  font-weight: var(--w2);
  font-size: var(--s6);
  font-style: italic;    
  color: var(--activeGreen);
}
.cmsWrapper header button{
  padding: .25rem 1rem;
  background-color: var(--green);
  border-radius: 10px;
  font-family: var(--f2);
  font-weight: var(--w3);
  font-size: var(--s3);    
  color: var(--white);
  border: 2px solid var(--green);
  cursor: pointer;
}
.cmsWrapper header button:hover{
  border: 2px solid var(--activeGreen);
}
.cmsWrapper header button:active{
  background-color: var(--activeGreen);
}