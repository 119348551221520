.programLanding{
    width: 100vw;
    margin-top: 10%;
    position: relative;
    margin-bottom: 5rem;
}
.programLanding figure{
    width: 100%;
}
.programLanding figure img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.programLanding main{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.programLanding main header{
    margin-bottom: 2rem;
}
.programLanding main header h1{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    text-align: center;
}
@media screen and (max-width:768px) {
    .programLanding{
        width: 80vw;
        margin-left: 10vw;
        margin-top: 17.5rem;
        margin-bottom: 15rem;
    }
}
/* ============================================================== Programs */
.programsContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.programsContainer header{
    margin-bottom: 5rem;
    display: inline-flex;
    flex-direction: column;
}
.programsContainer header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s5);    
    color: var(--black);
    text-align: center;
}
.programs{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 2rem 0;
}

/* ================================= */

.program{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* gap: 5rem; */
    margin-bottom: 5rem;
    padding: 2rem 5rem;

    flex-direction: column;
}
.programCard{
    width: 100%;
    display: flex;
    gap: 5rem;
    /* flex-direction: column; */
}
.programTitle{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s5);    
    color: var(--black);
    /* text-align: center; */
    margin-bottom: 2rem;
}
.programImage{
    /* background-color: var(--grey); */
    min-width: 350px;
    height: 450px;    
    /* overflow: hidden; */
}
.programImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    /* border-radius: 25px; */
}
.programContext{
    width: 50%;
    min-height: 450px;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
}
.programContext h3{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s4);    
    color: var(--black);
    /* text-align: center; */
    margin-bottom: 2rem;
}
.programDetails{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--black);
    text-align: justify;
}
.programSpecs{
    display: flex;
    width: 100%;
    /* margin-bottom: 2rem; */
}
.programSpecsCol{
    display: flex;
    width: 60%;
    flex-direction: column;
}
.AgeSpec{
    width: 30%;
    margin-right: 10%;
}
.programSpecsTitle{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}
.programSpecsTitle figure{
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.programSpecsTitle figure img{
    width: 100%;
    object-fit: contain;
}
.programSpecsTitle p{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);    
    color: var(--black);
}
.programSpecsRow{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 50px;
}
.programSpecsRow p{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--black);
    background-color: var(--pista);
    padding: .25rem .5rem;
    border-radius: var(--s2);
    margin-bottom: .5rem;
}
.programContext aside{
    margin-top: auto;
}
.programContext aside div{
    display: inline-block;
    cursor: pointer;
}
.programList{
    margin-top: 2rem;
    margin-bottom: 3rem;
    list-style-type: none;
}
.programList li{
    margin-bottom: .25rem;
}
@media screen and (max-width:768px) {
    .program{
        flex-direction: column;
        padding: 2rem 0;
    }
    .programImage{
        min-width: 280px;
        height: 350px;
    }
    .programContext{
        width: 100%;
    }
    .programSpecs{
        flex-direction: column;
        margin-bottom: 2rem;
    }
    .programSpecsCol{
        width: 100%;
    }
    .programCard{
        flex-direction: column;
    }
}

/* ==================================================== Specs */
.classSpecs{
    width: 100%;
    padding: 2rem 3.5rem;
}
.classSpecs h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s5);    
    color: var(--black);
    margin-bottom: 2rem;
}
.classSpecs h3{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);    
    color: var(--black);
    margin-bottom: .5rem;
}
.classSpecs ul{
    margin-bottom: 2rem;
}
.classSpecs li{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--black);
    margin-bottom: .25rem;
    margin-left: 2rem;
}
@media screen and (max-width:768px) {
    .classSpecs{
        padding: 2rem .25rem;
    }
}