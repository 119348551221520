.loginScreen{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.loginContainer{
    width: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* background-color: var(--biscut); */
    background-color: var(--pista);
    padding: 1rem;
    padding-top: 2rem;
    border-radius: 25px;
    border: 2px solid var(--activeGreen);
}
.loginContainer h1{
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    color: var(--green);
    text-align: center;
    margin-bottom: 1.5rem;
}
.loginInput{
    width: 100%;
    margin-bottom: 1rem;
}
.loginInput input{
    width: 100%;
    padding: .3rem 1rem;
    border: none;
    border-radius: 8px;
    background-color: var(--cream);
    color: var(--green);
    
    font-size: 16px;
}
.loginInput input:focus{
    /* border: 2px solid var(--wheat); */
    outline:2px solid var(--green);
}
.loginInput input::placeholder{
    color: var(--green);
}
.checkboxLogin{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: .5rem;
}
.checkboxLogin input{
    width: .75rem;
}
.checkboxLogin label{
    color: var(--green);

    font-size: 14px;
    font-weight: 600;
}
.submitLogin{
    justify-content: center;
}
.submitLogin input{
    width: auto;
    background-color: var(--green);
    color: var(--white);

    font-weight: 600;
    padding: .5rem 1rem !important;
}
.submitLogin input:active{
    background-color: var(--activeGreen);
    color: var(--white);
}