.footer{
    width: 100%;
    background-color: #EAEAEA;
    padding: 5rem 7rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}
.colMain{
    width: 40%;
    padding-left: 5rem;
}
.colMain header h2{
    font-family: var(--f6);
    font-weight: var(--w1);
    font-style: italic;
    font-size: 48px;    
    color: var(--green);
    margin-bottom: 1rem;
}
.addressFooter{
    margin-bottom: 1rem;
}
.addressFooter h3{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
    margin-bottom: .25rem;
}
.addressFooter p{
    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s1);    
    color: var(--black);
}
.contactFooter{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 1rem;
}
.contactFooter figure{
    width: 30px;
    display: flex;
}
.contactFooter figure img{
    width: 100%;
    object-fit: contain;
}
.contactFooter h3{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
}
.contactFooter p{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--black);
}
.footerIcons{
    gap: 1rem;
    margin-top: 2rem;
}
.col{
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
    padding-left: 8rem;
    padding-top: 2rem;
}
.col a{
    font-family: var(--f3);
    font-weight: var(--w2);
    font-size: var(--s2);    
    color: var(--black);
    text-decoration: none;
}
.footerLogo{
    position: absolute;
    bottom: 10%;
    right: 7.5%;
}
.footerLogo img{
    width: 100%;
    object-fit: contain;
    mix-blend-mode: darken;
}

.trulle{
    background-color: #EAEAEA;
    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
    /* width: 100%; */
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
}
.trulle a{
    color: #FF5E52;
    text-decoration: none;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .footer{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        padding: 1rem;
    }
    .colMain{
        grid-column: span 2;
        width: 100%;
        padding: 1rem;
    }
    .col{
        width: 100%;
        padding: 1rem;
        gap:1rem;
        margin-bottom: 0;
    }
    .footerLogo{
        right: auto;
    }
}