.cmsList{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.list{
    width: 80%;
    padding: 1rem 5rem;
}
.list h2{
    font-family: var(--f1);
    font-weight: var(--w2);
    font-size: var(--s4);    
    color: var(--activeGreen);
}
.list table{
    margin-top: 2rem;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    /* border: 1px solid var(--activeGreen); */
}
.list table thead th{
    border-bottom: 1px solid var(--activeGreen);
}
.list table tbody td{
    border-bottom: 1px solid var(--activeGreen);
}
.btns{
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
}
.btns a{
    padding: .25rem 1rem;
    background-color: var(--green);
    border-radius: 10px;
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);    
    color: var(--white);
    border: 2px solid var(--green);
    cursor: pointer;
    text-decoration: none;
    width: 150px;
    text-align: center;
}
.tableBtn{
    background-color:var(--activeGreen);
    display: inline-block;
    width:80%;
    color: var(--pista);
    font-family: var(--f1);
    font-weight: var(--w2);
    font-size: var(--s2);
    padding: .2rem 0;
    border: none;
    border-radius: 5px;
    text-decoration: none;
}