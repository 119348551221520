.sourceLanding{
    width: 100%;
    margin-top: 10%;
    margin-bottom: 5rem;
}
.sourceLanding figure{
    width: 100%;
    position: relative;
}
.sourceLanding figure img{
    width: 100%;
}
.sourceBtn{
    position: absolute;
    bottom: 0;
    right: 0;
    border: 5px solid var(--white);
    background-color: var(--white);
    padding: 5px;
    border-top-left-radius: 25px;
}
.sourceBtn::before{
    content: "";
    position: absolute;
    bottom: -.5px;
    left: -35.5px;
    width: 30px;
    height: 30px;
    /* background-color: blue; */
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 15px 15px 0 var(--white);
}
.sourceBtn::after{
    content: "";
    position: absolute;
    top: -34.5px;
    right: -5px;
    width: 30px;
    height: 30px;
    /* background-color: blue; */
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 15px 15px 0 var(--white);
}
.sourceDownload{
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-left: 10%;
    margin-bottom: 5rem;
}
.sourceDownload header{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}
.sourceDownload header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s5);    
    color: var(--black);
}
.sourceDownload main{
    width: 100%;
    padding: 2rem;
    border: 3px solid var(--green);
    border-radius: 25px;
}
.sourceDownload main h3{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
}
.download{
    padding: .5rem 0 .5rem 0;
    height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid var(--green);
}
.download aside{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 0;
}
.download figure{
    width: 30%;
    height: 100%;
    margin-right: 5%;
}
.download figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.download p{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
    margin-right: auto;
}
.download a{
    padding: .5rem 2rem;
    border: none;
    background-color: var(--green);
    font-family: var(--f3);
    font-weight: var(--w2);
    font-size: var(--s1);    
    color: var(--white);
    border-radius: 2rem;
    cursor: pointer;
    text-decoration: none;
}

@media screen and (max-width:768px) {
    .sourceLanding{
        margin-top: 7rem;
    }
    .sourceLanding figure img{
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow: hidden;
    }
    .sourceDownload{
        width: 100%; 
        margin-left: 0  
    }
    .sourceDownload main{
        padding: 1.5rem .5rem;
    }
    .download{
        flex-direction: column;
        align-items: flex-start;
    }
    .download a{
        margin-left: auto;
        margin-top: .5rem;
    }
}