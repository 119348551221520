.aboutLanding{
    margin-top: 10%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5rem;
}
.aboutLanding main h1{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    text-align: left;
    margin-bottom: 1rem;
}
.aboutLandinBtn{
    display: inline-block;
}
@media screen and (max-width:768px){
    .aboutLanding{
        flex-direction: column;
        padding: 1rem;
        margin-top: 5rem;
    }
    .aboutLanding main{
        width: 100%;
        margin-bottom: 2rem;
    }
    .aboutLanding figure{
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .aboutLanding figure img{
        width: 50%;
        object-fit: contain;
    }
}
/* ======================================About Us */
.aboutUs{
    margin-top: 3rem;
    margin-bottom: 5rem;
}
.aboutUs header{
    display: inline-flex;
    margin-bottom: 2rem;
    flex-direction: column;
}
.aboutUs h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s5);    
    color: var(--black);
    text-align: left;
}
.aboutUs p{
    width: 100%;
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
    text-align: justify;
}
.otherAbout{
    padding: 0 5rem;
    margin-bottom: 1rem;
}
.otherAbout p{
    width: 100%;
    text-align: justify;
}
@media screen and (max-width:768px){
    .aboutUs{
        width: 100%;
    }
    .aboutUs p{
        width: 100%;
    }
    .otherAbout{
        padding: 0;
    }
}
/* ============================================= Approach */
.approach{
    width: 100%;
    margin-top: 5rem;
    padding: 0 5rem;
    margin-bottom: 5rem;
}
.approach header{
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 2rem;
}
.approach header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s5);    
    color: var(--black);
    text-align: left;
}
.approach main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.approach main p{
    width: 40%;
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
    text-align: justify;
}
.approach figure{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.approach figure img{
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width:768px){
    .approach{
        padding: 0;
    }
    .approach main{
        flex-direction: column-reverse;
    }
    .approach main p, .approach main figure{
        width: 100%;
    }
    .approach main figure{
        margin-bottom: 2rem;
    }
}
/* ========================================Jikina */
.aboutJikina{
    margin: 5rem 0;
}