.homeLanding{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 10%;
}
.homeLanding figure{
    width: 350px;
    height: 315px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    animation: landing 1s ease;
}
@keyframes landing {
    from{
        width: 100%;
    }
    to{
        width: 350px;
    }
}
.square{
    width: 300px;
    height: 265px;
    border-radius: 24px;
    background-color: #F8F8F8;
    border-radius: 25px;
    z-index: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.square img{
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.smallSquare{
    width: 114px;
    height: 114px;
    border-radius: 40px;
    position: absolute;
    z-index: 0;
    animation: small 1s ease;
    opacity: 1;
}
@keyframes small {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
.cream{
    background-color: #C2E8C9;
    top: 0;
    left: 0;
}
.blue{
    background-color: #2E7BF6;
    bottom: 0;
    right:  0;
}
.homeLanding header{
    width: 70%;
    margin-top: 1rem;
}
.homeLanding header h1{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    text-align: center;
}
.homeLanding aside{
    margin-top: 2rem;
}
@media screen and (max-width:768px) {
    .homeLanding{
        margin-top: 30%;
    }
    .homeLanding figure{
        transform: scale(80%);
    }
    .homeLanding header{
        width: 90%;
    }
}
.phoneBtn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    z-index: 2;
    bottom: 5%;
    right: 5%;
}


/* ====================================== Landing 2 */
.homeLanding2{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 10%;
    padding-bottom: 10%;
}
.landing2Conatiner{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 5%;
    gap: 5rem;
}
.landing2Conatiner main{
    width: 45%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.landing2Conatiner main figure{
    width: 75%;
}
.landing2Conatiner main figure img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.landing2Conatiner main header h1{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    text-align: left;
    margin-top: 1rem;
}
.landing2Btn{
    display: inline-block;
    margin-top: 2rem;
}


.landing2Conatiner figure{
    width: 35%;
}
.landing2Conatiner figure img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}

@media screen and (max-width:768px) {
    .homeLanding2{
        margin-top: 25%;
    }
    .landing2Conatiner{
        flex-direction: column-reverse;
        margin-top: 1rem;
        gap: 1rem;
    }
    .landing2Conatiner main{
        width: 100%;
        align-items: center;
    }
    .landing2Conatiner main header h1{
        text-align: center;
    }
    .landing2Conatiner figure{
        width: 100%;
    }
}
/* ====================================== Scrolll */
.homeScroll{
    margin-top: 4rem;
}
.scroller{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s4);    
    color: var(--black);
    text-decoration: none;
}
/* ======================================== Secondary  */
.homeColorBg1{
    width: 100vw;
    background: rgb(235,247,237);
    background: linear-gradient(180deg, rgba(235,247,237,1) 0%, rgba(255,255,255,1) 100%);
    margin-top: 2rem;
    border-radius: 40px;
}
.homeNote{
    justify-content: space-between;
    flex-direction: row;
    padding: 10rem 2.5rem;
}
.homeNote main{
    width: 55%;    
}
.homeNote main h2{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s5);    
    color: var(--black);
}
.homeNote main p{
    margin-top: 2rem;
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
}
.homeNote figure{
    width: 30%;
    margin-right: 3rem;
}
.homeNote figure img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
@media screen and (max-width:768px) {
    .homeNote{
       flex-direction: column-reverse; 
       padding: 5rem .5rem;
    }
    .homeNote figure{
        width: 100%;
        margin-bottom: 3rem;
    }
    .homeNote main{
        width: 100%;
    }
    .homeNote main h2{
        margin-bottom: 3rem;
    }
    .homeNote main p{
        text-align: justify;
    }
}
/* ==================================================Home Note */
.homeStories{    
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 10rem;
    padding-right: 0;
}
.homeStories main{
    width: 25%;
    margin-left: 3rem;
}
.homeStories main h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
}
.homeStoryDes{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
    margin-bottom: 1.5rem;
}
.homeStories main div{
    /* margin-top: 1.5rem; */
    display: inline-block;
}
.homeStories aside{
    width: 65%;
    background-color: #ffffff;
    /* background-color: #2E7BF6; */
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    overflow-x: hidden;
}
.StorySlider{
    width: auto;
    
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    transition: all 1s ease;
}
.storyContainer{
    margin-top: 2rem;
    margin-left: 50px;
    margin-bottom: 1rem;
    width: 199px;
    /* height: 200px; */
    text-decoration: none;
}
.storyContainer figure{
    width: 100%;
    height: 210px;
    margin-bottom: .5rem;
}
.storyContainer figure img{
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.storyContainer p{
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0 .25rem;

    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--grey);
}
.storyNavigator{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    padding: 1rem 5rem;
    padding-bottom: 1.5rem;
}
.leftArrow{
    transform: rotate(180deg);
}
@media screen and (max-width:768px) {
    .homeStories{
        margin-top: 1rem;
        padding:  0rem;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
    .homeStories aside{
        width: 85%;
        margin-bottom: 3rem;
    }
    .storyNavigator{
        justify-content: flex-start;
        padding: 1.5rem;
        padding-left: 3rem;
    }
    .homeStories main{
        width: 80%;
    }
}
/* ======================================Mission Vision */
.MisVis{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5rem;
    margin: 7rem 0;
}
.MisVisContainer{
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--blue);
    padding: 1.5rem;
    padding-bottom: 0;
    border-radius: 25px;

    min-height: 430px;
    overflow: hidden;
}
.MisVisContainer main{
    width: 100%;
}
.MisVisContainer main h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    margin-bottom: 1rem;
    text-align: center;
}
.MisVisContainer main p{
    font-family: var(--f2);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--grey);
    text-align: justify;
}
.MisVisContainer figure{
    margin-top: auto;
    width: 100%;
    height: 150px;
    /* background-color: #2E7BF6; */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.MisVisContainer figure img{
    height: 100%;
    /* width: 90%; */
    object-fit: contain;
    object-position: center;
}
@media screen and (max-width:768px) {
    .MisVis{
        /* flex-direction: column; */
        /* align-items: center; */
        gap: .5rem;
    }
    .MisVisContainer{
        width: 80%;
        margin: 0;
    }
}
/* ========================================== BG2 */
.homeColorBg2{
    width: 100vw;
    background: rgb(237,246,255);
    background: linear-gradient(180deg, rgba(237,246,255,1) 0%, rgba(255,255,255,1) 100%);
    margin-top: 2rem;
    border-radius: 40px;
}
.homePrograms{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 5rem;
    margin-bottom: 10rem;
}
.homePrograms header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5rem;
}
.homePrograms header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    line-height: var(--s6);
}
.programContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: .75rem;
}
.programContainer main{
    width: 15%;
    min-height: 500px;
    background-color: var(--white);
    /* background-color: #2E7BF6; */
    border-radius: 25px;
    padding: 1.5rem;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    box-shadow: 0px 4px 4px 0px #0000001A;

}
.programContainer main h3{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s4);    
    color: var(--activeGreen);
    margin-bottom: 1.5rem;
    /* height: 70px; */
    text-align: left;
    width: 100%;
}
.programContainer main p{
    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s1);    
    color: var(--grey);
    margin-bottom: auto;
}
.programContainer main div{
    padding-top: 1.5rem;
    display: inline-block;
}
@media screen and (max-width:768px) {
    .homePrograms{
        margin-bottom: 1rem;
        padding-top: 2rem;
    }
    .homePrograms header{
        margin-bottom: 2rem;
    }
    .programContainer{
        flex-direction: column;
        align-items: center;
    }
    .programContainer main{
        width: 80%;
        justify-content: flex-start;
        align-items: flex-start;
        min-height: auto;
    }
    .programContainer main h3{
        margin-bottom: 0;
    }
    .programContainer main p{
        text-align: justify;
        display: none;
    }
    .programContainer main div{
        margin-left: auto;
    }
}

/* ============================================= Certificate */
.homeCertificate{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.homeCertificate figure{
    width: 45%;
    /* border-right: 2px solid var(--green); */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 3.5rem;
}
.homeCertificate figure img{
    width: 80%;
    object-fit: contain;
}
.homeCertificate div{
    width:45%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: .5rem 3.5rem;
    border-left: 3.5px solid var(--green);
}
.homeCertificate div main{
    padding: 1rem;
    margin-right: 20%;
    border-bottom: 3px solid var(--green);
}
.homeCertificate div main:last-child{
    border: none;
    padding-bottom: 0px;
}
.homeCertificate div main h3{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s4);    
    color: var(--activeGreen);
    margin-bottom: .5rem;
}
.homeCertificate div main p{
    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--grey);
    margin-bottom: .5rem;
}

@media screen and (max-width:768px) {
    .homeCertificate{
        flex-direction: column;
        margin-top: 5rem;
    }
    .homeCertificate figure{
        width: 100%;
        justify-content: center;
        padding: 0;
        margin-bottom: 2rem;
    }
    .homeCertificate figure img{
        width: 90%;
    }
    .homeCertificate div{
        /* border-top: 2.5px solid var(--green); */
        border-left: 0;
        padding: 1rem;
        width: 100%;
        align-items: center;
    }
    .homeCertificate div main{
        margin-right: 0;
    }
}

/* ============================================= home why */
.homeWhy{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 5rem;
    margin-bottom: 10rem;
}
.homeWhy header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
}
.homeWhy header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    line-height: var(--s6);
}
.homeWhy main{
    width: 70%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-top: 5rem;
}
.homeWhy main details{
    width: 100%;
    position: relative;
    margin-bottom: .5rem;
}
.homeWhy main details summary{
    padding: 2px 6px;
    width: 100%;
    cursor: pointer;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1rem 2rem;
    background-color: var(--pista);
    border-radius: 10px;

}
.homeWhy main details summary h4{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s4);    
    color: var(--black);
}
.homeWhy main details summary h5{
    font-family: var(--f4);
    font-weight: var(--w2);
    font-size: var(--s5);    
    color: var(--green);
    line-height: 0;
    transition:all .25s ease;
}
.homeWhy main details[open] summary h5{
    transform: rotate(135deg);
}
.homeWhy main details p{
    width: 100%;
    padding: 1rem;
    background-color: var(--cream);
    border-radius: 10px;
    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--black);
}
@media screen and (max-width:768px) {
    .homeWhy{
        margin-bottom: 5rem;
    }
    .homeWhy header{
        margin-bottom: 0rem;
    }
    .homeWhy main{
        width: 90%;
    }
    .homeWhy main details p{
        text-align: justify;
    }
}
/* ============================================== Publish */
.homePublish{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin: 7rem 0;
}
.homePublish header{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start; 
    flex-direction: column; 
    margin-bottom: 2rem;  
}
.homePublish header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
}
.homePublish figure{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    flex-wrap: wrap;
}
/* ============================================== Founder */
.founder{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;
    margin-left: 10%;
    margin-bottom: 10rem;
}
.forMobFounderHead{
    display: none;
}
.founderImage{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.founderImage figure{
    width: 100%;
    display: flex;
    justify-content: center;
}
.founderImage figure img{
    width: 50%;
    object-fit: contain;
}
.founderImage h3{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);    
    color: var(--black);
    text-align: center;
    margin-top: 1rem;
}
.founderImage h4{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s1);    
    color: var(--black);
    text-align: center;
    margin-top: .5rem;
}
.founderSays{
    width: 50%;
}
.founderSays header{
    width: 100%;
    margin-bottom: 2rem;
}
.founderSays header h2{
    display: inline-flex;
    flex-direction: column;
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s5);    
    color: var(--black);
}
.founderSays p{
    font-family: var(--f7);
    font-weight: var(--w1);
    font-size: 22px;    
    color: var(--black);
    text-align: justify;
}
@media screen and (max-width:768px) {
    .forMobFounderHead{
        display: block;
        width: 100%;
        margin-bottom: .5rem;
    }
    .forMobFounderHead h2{
        display: inline-flex;
        flex-direction: column;
        font-family: var(--f2);
        font-weight: var(--w3);
        font-size: var(--s5);    
        color: var(--black);
    }
    .founder{
        width: 90%;
        margin-left: 5%;
        flex-direction: column;
        margin-bottom: 3rem;
    }
    .founderSays{
        width: 100%;
        margin-top: 2rem;
    }
    .founderSays header{
        display: none;
    }
    .founderImage{
        width: 100%;
    }
    .founderImage figure img{
        width: 60%;
    }
}
/* ================================================ board */
.board{
    width: 90%;
    margin-left: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5rem;
}
.board h2{
    font-family: var(--f2);
    font-weight: var(--w3);
    font-size: var(--s5);    
    color: var(--black);
    margin-bottom: 3rem;
    text-align: center;
}
.members{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 2rem;

}
.member{
    width: 30%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    background-color: var(--cream);
    padding: 1rem;
    height: 300px;
    border-radius: 30px;
}
.member figure{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 1rem;
    /* background-color: #2E7BF6; */
}
.member figure img{
    width: 30%;
    object-fit: contain;
}
.member h3{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s3);    
    color: var(--black);
    text-align: center;
    margin-bottom: .25rem;
}
.member h4{
    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s1);    
    color: var(--black);
    text-align: center;
}

@media screen and (max-width:768px) {
    .board{
        width: 100%;
        margin-left: 0;
    }
    .members{
        flex-direction: column;
        gap: 1rem;
    }
    .member{
        width: 100%;
        height: 250px;
        padding: 0.1rem;
    }
    .member figure{
        margin-bottom: .5rem;
    }
}
/* ================================================= Touch */
.touch{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 5rem;
    margin-bottom: 7rem;
}
.touchNote{
    width: 35%;
    padding: 1.5rem;
}
.touchNote h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    margin-bottom: 2rem;
}
.touchNote p{
    font-family: var(--f4);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
    text-align: justify;
}
.touchNote p span{
    height: 1px;
    background-color: var(--balck);
    width: 100%;
    display: inline-block;
    margin: 1rem 0;
}
.touchContact{
    width: 40%; 
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 3.5rem;
    padding-bottom: 2rem;
    background-color: var(--cream);
    border-radius: 30px;   
}
.touchContact h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    margin-bottom: 2rem;
}
.touchContact form{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}
.inputContainer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
}
.inputContainer label{
    font-family: var(--f3);
    font-weight: var(--w2);
    font-size: var(--s2);    
    color: var(--black);
    margin-bottom: .25rem;
}
.inputContainer input, .inputContainer select{
    width: 100%;
    border: none;
    padding: .5rem .5rem;
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s2);    
    color: var(--black);
    border-radius: var(--s2);
}
.inputContainer input:focus{
    outline: 3px solid var(--green);;
}
.submitContainer{
    margin-top: 1rem;
}
.mobTouch{
    display: none;
}

@media screen and (max-width:768px) {
    .touch{
        flex-direction: column;
        gap: 1rem;
    }
    .touchNote{
        width: 100%;
        display: none;
    }
    .mobTouch{
        display: block;
        padding: 1rem;
        padding-bottom: .5rem
    }
    .mobTouch h2{
        margin-bottom: .5rem;
    }
    .touchContact{
        width: 100%;
        padding: 1.5rem;
    }
}
/* ============================================================Notification */
.notificationContainer{
    position: fixed;
    top: 85%;
    right: 10%;
    z-index: 5;
}
.notificationIcon{    
    background-color: var(--cream);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    cursor: pointer;
    transition: .5s ease;
}
.notificationIcon img{
    object-fit: contain;
    width: 70%;
    object-position: center;
}
.showIcon{
    width: 40px;
    height: 40px;
    opacity: 1;
    bottom: 0;
    right: 0;
}
.hideIcon{
    width: 0;
    height: 0;
    opacity: 0;
    pointer-events: none;
    top: 0;
    right: 0;
}
.notification{
    border-radius: 25px;
    background-color: var(--pista);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: .5s ease;
    overflow: hidden;
}
.notification header{
    padding: .5rem 1.5rem;
    background-color: var(--green);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notification header h3{
    font-family: var(--f1);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--cream);
}
.notice h4{
    font-family: var(--f1);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--black);
}
.notice p{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s2);
    color: var(--black);
}
.notification header img{
    cursor: pointer;
}
.notice{
    padding: .5rem 1.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: .5rem;
}
.notice article{
    width: 100%;
    margin-bottom: .5rem;
}
.notice article a{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-decoration: none;
    color: var(--notify);
}
.notice article a main{
    width: 80%;    
    overflow: hidden;
}
.notice article a main p{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.displayContainer{
    width: 250px;
    /* height: 300px;     */
    position: absolute;
    bottom: 0;
    right: 0;
}
.noDisplayContainer{
    width: 0;
    /* height: 0; */
    opacity: 0;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    right: 0;
}

/* ============================================= NewsLetter */
.homeNewsletter{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 5rem;
    margin-bottom: 10rem;
}
.homeNewsletter header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5rem;
}
.homeNewsletter header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    line-height: var(--s6);
}
.letterContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: .75rem;
}

@media screen and (max-width:768px) {
    .homeNewsletter{
        padding-top: 1rem;
        margin-bottom: 5rem;
    }
    .letterContainer{
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }
    /* .newsLetter{
        display: none;
    } */
    .newsLetter:first-child{
        display: flex;
    }
    .homeNewsletter header{
        margin-bottom: 1rem;
    }
}