.reviewMain{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 7rem;
}
.reviewMain header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);
    color: var(--black);
    line-height: var(--s6);
}
.reviewMain header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5rem;
}
.reviewSlider{
    width: 100%;
    /* padding-left: 2rem; */
}
.reviewMain article{
    /* display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden; */

    /* animation: reviewRight 10s infinite linear; */
}
/* @keyframes reviewLeft {
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-70%);
    }
} */
/* @keyframes reviewRight {
    0%{
        transform: translateX(-45%);
    }
    100%{
        transform: translateX(35%);
    }
} */

.reviewMain main{
    width: 416px;
    height: 180px;
    padding: 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
    background-color: var(--cream);
    border-radius: 10px;
    margin: .25rem;
}
.reviewMain main p{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s2);
    color: var(--black);
}
.reviewMain main aside{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reviewMain main aside figure{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: .25rem;
}
.reviewMain main aside figure img{
    width: 25px;
}
.reviewMain main aside h4{
    font-family: var(--f3);
    font-weight: var(--w2);
    font-size: var(--s3);
    color: var(--grey);
    text-align: right;
}

