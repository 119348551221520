.navContainer{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    position: fixed;
    z-index: 10;
    
}
.navweb{
    width: 90%;
    padding: .5rem 3rem;
    padding-left: .5rem;
    padding-right: 5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 50px;
    transition: all .5s ease;
    background-color: var(--white);
    gap: 2rem;
}
.navScrolled{
    width: 75%;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 36px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 1px;
}
.navweb figure{
    margin-right: auto;
}
.navweb figure a{
    font-family: var(--f6);
    font-weight: var(--w1);
    font-size: var(--s4);
    font-style: italic;
    text-decoration: none;
    color: var(--green);
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
    gap: .5rem;
}
.navweb figure a img{
    width: 60px;
}
.navweb main{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 2rem;
}
.navweb main a{
    font-family: var(--f3);
    font-weight: var(--w2);
    font-size: var(--s2);
    text-decoration: none;
    color: var(--black);
    letter-spacing: .75px
}
@media screen and (max-width:768px) {
    .navContainer{
        margin-top: .5rem;
    }
    .navweb{
        width: 95%;
        padding: .25rem 1rem;
    }
    .navScrolled{
        width: 95%;
    }
}
/* =================================menu */
.menuIconContainer{
    /* width: 100%; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4.25rem;
    position: absolute;
    z-index: 15;
    /* overflow: hidden; */
}
.menuBtn{
    width: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: static;
    right: 5rem; 
    cursor: pointer;
    z-index: 15;   
}
.bar{
    width: 100%;
    height: 3px;
    margin-bottom: 3px;
    background-color: var(--green);
    transition: all .5s ease-in-out;
    border-radius: 3px;
}
.bar1{
    transform: rotate(-135deg) translateX(-5px) translateY(-3.5px);
    background-color: var(--white);
}
.bar2{
    opacity: 0;
}
.bar3{
    transform: rotate(135deg) translateX(-5px) translateY(3.5px);
    background-color: var(--white);
}
/* ======================================== Drop */
.hideDrop{
    display: none;
}
.dropContainer{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    /* display: flex; */
}
.horizontal{
    width: 5%;
    height: 100%;
    background-color: var(--green);
    animation: horizontalAnimate .5s ease-in-out;
}
@keyframes horizontalAnimate {
    from{
        width: 0;
    }to{
        width: 5%;
    }
}
.vertical{
    position: absolute;
    right: 0;
    top:0;

    width: 95%;
    height: 100%;
    background-color: var(--green);
    display: flex;
    justify-content: center;
    padding-top: 10rem;
    gap: 5%;

    animation: verticalAnimate .25s ease;
}
@keyframes verticalAnimate {
    from{
        height: 0;
    }to{
        height: 100%;
    }
}
.dropCol{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 2rem;
    flex-direction: column;
    width: 20%;
}
.dropCol a{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s4);    
    color: var(--white);
    text-decoration: none;
}
.dropOther a{
    font-weight: var(--w3);
    font-size: var(--s2); 
}
.openMenu{
    position: absolute;
    top: 10%;
}
.indicator::before{
    content: '';
    background-color: var(--white);
    padding: 0 5px;
    margin-right: .25rem;
}

@media screen and (max-width:768px) {
    .vertical{
        flex-direction: column;
        padding-left: 2rem;
        padding-top: 2rem;
    }
    .dropCol{
        width: 100%;
        gap: 1rem;
    }
    .dropOther{
        margin-top: 2rem;
    }
}