.contactLanding{
    width: 100%;
    margin-top: 15%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 5rem;
}
.contactImage{
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
}
.contactImage img{
    width: 100%;
    object-fit: contain;
    object-position: center;
}
.contactLanding main{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.contactLanding main header h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--black);
    margin-bottom: 2rem;
}
.contactRow{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;
}
.contactIcon{
    
}
.contactIcon a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #5BC16F;
    height: 2rem;
    padding: .5rem 1rem;
    border-radius: var(--s3);
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--white);
    text-decoration: none;
}
.contactIcon img{
    height: 100%;
    object-fit: contain;
    border: none;
}
.contactList{
    font-family: var(--f3);
    font-weight: var(--w1);
    font-size: var(--s3);    
    color: var(--black);
}

@media screen and (max-width:768px) {
    .contactLanding{
        flex-direction: column;
        margin-top: 7rem;
    } 
    .contactImage{
        width: 100%;
        margin-bottom: 2rem;
    }  
    .contactLanding main{
        width: 100%;
    }
    .contactRow{
        gap: .5rem;
    }
    .contactIcon{
        gap: .5rem;
    }
}