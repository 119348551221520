.jikina{
    width: 100%;
    position: relative;
}
.jikinaBG{
    width: 100%;
}
.jikinaRow{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1rem;    
}
.dotPlain{
    width: 68px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--jikina);
    margin-left: 16px;
    margin-right: 16px;
    transition-delay: .5s;
    background-image: url("images/dummy_circle.png");
    transition: all .5s ease;
}
.dot{
    width: 68px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: var(--jikina);
    margin-left: 16px;
    margin-right: 16px;
    transition: all .5s ease;
    transition-delay: .5s;
    animation: spring .5s .5s ease;
}
.jikinaSection{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
}
.findContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.find{
    width: 450px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.find h2{
    font-family: var(--f1);
    font-weight: var(--w1);
    font-size: var(--s6);    
    color: var(--green);
    text-align: center;
}
.find p{
    font-family: var(--f1);
    font-weight: var(--w3);
    font-size: var(--s2);    
    color: var(--black);
    text-align: center;
    width: 80%;
}
.enrollBtn{
    margin: 2rem 0;
}

.animate{
    animation: spring .5s .5s ease;
}
.green{
    background-color: #32B14B;
}
.pista{
    background-color: #99D8A5;
}
.sky{
    background-color: #97BDFB;
}
.blue{
    background-color: #4388F7;
}
.avatar1{
    background-image: url("images/avatar1.png");
}
.avatar2{
    background-image: url("images/avatar2.png");
}
.avatar3{
    background-image: url("images/avatar3.png");
}

@keyframes spring {
    0%{
        transform: scale(100%);
    }
    33%{
        transform: scale(80%);
    }
    66%{
        transform: scale(120%);
    }
    100%{
        transform: scale(100%);
    }
}
/* ===================================================Mobile */
@media screen and (max-width:768px) {
    .jikina{
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .findContainer{
        position: relative;
        top: 0%;
        left: 0%;
        transform: translate(0%, 0%);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .dotPlain{
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .dot{
        width: 50px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .jikinaRow{
        padding: 0;
        justify-content: flex-start;
    }
    .jikinaSection{
        margin-bottom: .5rem;
    }
    .enrollBtn{
        margin: 1rem 0;
    }
    .find{
        width: 100%;
        height: auto;
        margin: 1.5rem 0;
    }
}